<template>
  <div style="margin-top: 20px">
    <div class="header">
      <div class="textNew">按能力提升</div>
      <div class="text">夯实基础，精准提升</div>
    </div>
    <ul class="list">
      <div
        class="box"
        v-for="(item, index) in list"
        :key="index"
        @click="handleSelect(item.id)"
      >
        <div class="icon">
          <div class="img">
            <template v-if="/http/.test(item.img_logo)">
              <img :src="item.img_logo" alt="" />
            </template>
            <template v-else>
              <i class="iconfont" :class="item.img_logo"></i>
            </template>
          </div>
        </div>
        <div class="text">{{ item.name }}</div>
      </div>
    </ul>
  </div>
</template>

<script>
import { apiCourseCate } from '@/api/course'

export default {
  data() {
    return {
      list: []
    }
  },
  components: {},
  created() {
    this.getTarget()
  },
  methods: {
    getTarget() {
      apiCourseCate().then((res) => {
        if (res.code == 200) {
          this.list = res.data[5].sub_list
        }
      })
    },
    handleSelect(cateId) {
      if (this.$route.query.cateSub === cateId) {
        return false
      }
      const cateData = this.list.find((m) => m.id === Number(cateId))
      this.$router.replace({
        name: 'course',
        query: {
          ...this.$route.query,
          cateMain: cateData.pid,
          cateSub: cateData.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
  .textNew {
    font-size: 26px;

    font-weight: bold;
    color: #1f1f40;
  }
  .text {
    font-size: 16px;

    font-weight: 400;
    color: #3a3a3a;
    margin-left: 12px;
    line-height: 1.5;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  .box {
    width: 217px;
    height: 57px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    opacity: 1;
    border-radius: 10px;
    display: flex;
    margin-right: 28px;
    margin-bottom: 24px;
    cursor: pointer;
    .icon {
      width: 29px;
      height: 29px;
      background: #c9e2ff;
      border-radius: 50%;
      margin-top: 14px;
      margin-left: 30px;
      .img {
        margin-top: 5px;
        margin-left: 7px;
      }
    }
    .text {
      font-size: 14px;

      font-weight: 400;
      color: #1f1f40;
      line-height: 57px;
      margin-left: 8px;
    }
  }
}
</style>
