<template>
  <div style="margin-top: 40px">
    <div class="header">
      <div class="textNew">按题型学习</div>
      <div class="text">从零起步到完整备考</div>
    </div>
    <div class="content">
      <div class="imgs">
        <img
          class="imgone"
          width="160"
          height="304"
          src="../../../../assets/live/man.png"
        />
        <img
          class="imgtwo"
          width="163"
          height="294"
          src="../../../../assets/live/gender.png"
        />
      </div>
      <div class="textBox">
        <el-row>
          <el-col :span="24" class="title"> Kevin WU </el-col>
          <el-col :span="24" class="text">
            12年一线教龄，PTEGO口写教研组长 PTE业内口语教学方法奠基人
          </el-col>
          <el-col :span="24" class="title"> Sissi LUO </el-col>
          <el-col :span="24" class="text">
            9年一线教龄，PTEGO听阅教研组长 集颜值与实力于一身的高分制造者
          </el-col>
          <el-col class="btn">
            <div class="textFoter">
              PTE王牌名师联合设计主讲 学练结合，短平快备考
            </div>
          </el-col>
          <div
            style="
              height: 197px;
              border-right: 1px solid #ffffff;
              margin-top: 25px;
              position: absolute;
              right: -37px;
              opacity: 0.2;
            "
          ></div>
        </el-row>
      </div>
      <div class="boxCenter">
        <el-row>
          <el-col :span="12">
            <div class="box">
              <img
                class="imgIcon"
                width="55"
                height="86"
                src="../../../../assets/live/ly.png"
              />
              <div class="text">口语</div>
              <div class="text2">
                共{{ statList.find((m) => m.id === 21).chapter_cnt }}课时
              </div>
              <el-button class="btn" type="primary" round @click="submit('ky')"
                >免费听课</el-button
              >
            </div>
          </el-col>
          <el-col :span="12">
            <div class="box">
              <img
                class="imgIcon"
                width="93"
                height="83"
                src="../../../../assets/live/x.png"
              />
              <div class="text">写作</div>
              <div class="text2">
                共{{ statList.find((m) => m.id === 22).chapter_cnt }}课时
              </div>
              <el-button class="btn" type="primary" round @click="submit('xz')"
                >免费听课</el-button
              >
            </div>
          </el-col>
          <el-col :span="12">
            <div class="box">
              <img
                class="imgIcon"
                width="64"
                height="80"
                src="../../../../assets/live/s.png"
              />
              <div class="text">阅读</div>
              <div class="text2">
                共{{ statList.find((m) => m.id === 23).chapter_cnt }}课时
              </div>
              <el-button class="btn" type="primary" round @click="submit('yd')"
                >免费听课</el-button
              >
            </div>
          </el-col>
          <el-col :span="12">
            <div class="box">
              <img
                class="imgIcon"
                width="93"
                height="75"
                src="../../../../assets/live/rj.png"
              />
              <div class="text">听力</div>
              <div class="text2">
                共{{ statList.find((m) => m.id === 24).chapter_cnt }}课时
              </div>
              <el-button class="btn" type="primary" round @click="submit('tl')"
                >免费听课</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { courseStat } from '@/api/course'

export default {
  data() {
    return {
      statList: []
    }
  },
  created() {
    this.fetchStat()
  },
  methods: {
    async fetchStat() {
      const { data } = await courseStat()
      this.statList = data
    },
    submit(v) {
      if (v == 'ky') {
        this.$router.push({
          name: 'course',
          query: {
            cateMain: 2,
            cateSub: 21
          }
        })
      }
      if (v == 'xz') {
        this.$router.push({
          name: 'course',
          query: {
            cateMain: 2,
            cateSub: 22
          }
        })
      }
      if (v == 'yd') {
        this.$router.push({
          name: 'course',
          query: {
            cateMain: 2,
            cateSub: 23
          }
        })
      }
      if (v == 'tl') {
        this.$router.push({
          name: 'course',
          query: {
            cateMain: 2,
            cateSub: 24
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 64px;
  .textNew {
    font-size: 26px;

    font-weight: bold;
    color: #1f1f40;
  }
  .text {
    font-size: 16px;

    font-weight: 400;
    color: #3a3a3a;
    margin-left: 12px;
    line-height: 1.5;
  }
}
.content {
  width: 952px;
  height: 247px;
  background: #4a4a58;
  border-radius: 10px;
  position: relative;
  .imgs {
    position: relative;
    .imgone {
      position: absolute;
      left: 20px;
      bottom: -247px;
    }
    .imgtwo {
      position: absolute;
      left: 100px;
      bottom: -247px;
    }
  }
  .textBox {
    width: 247px;
    height: 247px;
    margin-left: 268px;
    .title {
      font-size: 14px;

      font-weight: bold;
      line-height: 34px;
      color: #e3c497;
    }
    .text {
      font-size: 12px;

      font-weight: 400;
      line-height: 20px;
      color: #e3c497;
      opacity: 0.6;
    }
    .btn {
      height: 67px;
      background: #e3c497;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      margin-top: 12px;
      .textFoter {
        width: 192px;
        height: 46px;
        font-size: 16px;

        font-weight: bold;
        color: #4a4a58;
        text-align: center;
        margin: 0 auto;
        margin-top: 12px;
      }
    }
  }
  .boxCenter {
    width: 410px;
    height: 247px;
    position: absolute;
    top: 0;
    right: 0;
    .box {
      width: 205px;
      height: 118px;
      background: url('../../../../assets/live/03.png');
      position: relative;
      cursor: pointer;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-8px);
      }
      .imgIcon {
        position: absolute;
        left: 20px;
        top: 26px;
        pointer-events: none;
      }
      .text {
        font-size: 18px;

        font-weight: bold;
        color: #1f1f40;
        position: absolute;
        top: 33px;
        left: 36px;
      }
      .text2 {
        font-size: 12px;

        font-weight: 400;
        color: #1f1f40;
        position: absolute;
        top: 40px;
        left: 119px;
      }
      .btn {
        width: 130px;
        height: 28px;
        background: #f7a437;
        opacity: 1;
        border-radius: 14px;
        font-size: 14px;

        font-weight: 400;
        color: #ffffff;
        border-color: #f7a437;
        line-height: 1px;
        margin-top: 70px;
        margin-left: 35px;
      }
    }
  }
}
</style>
