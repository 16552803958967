<template>
  <li class="try-item">
    <div class="top">
      <div class="box" v-if="typeInfo.type">
        <span>{{ typeInfo.type }}</span>
      </div>
      <span class="exc-title">{{ info.name }}</span>
      <div class="progress">
        <div :style="{ width: `${info.learn_rate}%` }"></div>
      </div>
    </div>
    <div class="middle">
      <div
        v-for="item of chapterList"
        :key="item.id"
        @click="handleChapterDetail(item)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="bottom">
      <div class="buy" v-if="info.buy_status == 1">
        <i class="iconfont icon-lock"></i>
        <span>已购买</span>
      </div>
      <div class="buy" v-else>
        <i class="iconfont icon-suoding"></i>
        <span>未购买</span>
      </div>
      <el-button type="text" @click="handleDetail">开始学习</el-button>
    </div>
  </li>
</template>

<script>
import { TYPE_INFO } from '@/utils/type-list'
import { apiChapterList } from '@/api/course'

export default {
  props: {
    info: {
      type: Object,
      defualt: () => ({})
    }
  },
  data() {
    return {
      chapterList: []
    }
  },
  computed: {
    typeInfo() {
      return (
        Object.values(TYPE_INFO).find(
          (m) => this.info.name.toLowerCase() === m.h1.toLowerCase()
        ) || { type: '' }
      )
    }
  },
  mounted() {
    this.fetchChapterList()
  },
  methods: {
    async fetchChapterList() {
      const { data } = await apiChapterList({
        course_id: this.info.id
      })
      this.chapterList = data
    },

    handleDetail() {
      switch (Number(this.info.type)) {
        case 1:
          {
            this.$router.push({
              name: 'chapterDetailText',
              query: {
                skillId: this.info.id
              }
            })
          }
          break
        case 2:
          {
            this.$router.push({
              name: 'chapterDetail',
              query: {
                skillId: this.info.id,
                type: 1
              }
            })
          }
          break
        case 3:
          {
            this.$router.push({
              name: 'courseChapter',
              query: {
                cateId: this.info.cate_main,
                skillId: this.info.id
              }
            })
          }
          break
      }
    },

    handleChapterDetail(item) {
      this.$router.push({
        name: 'chapterDetail',
        query: {
          skillId: item.course_id,
          chapterId: item.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.try-item {
  width: 100%;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 32px;
  margin: 30px 12px 0 12px;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-8px);
  }
  .top {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    justify-content: space-between;

    .box {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background: #ff606d;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      margin-right: 14px;

      > span {
        color: #ffffff;
      }
    }

    .exc-title {
      flex: 1;
      font-size: 18px;
      font-weight: bold;
    }

    .progress {
      width: 240px;
      height: 9px;
      border-radius: 10px;
      background-color: #f0f0f0;
      > div {
        border-radius: 10px;
        height: 9px;
        background: #3b86ff;
      }
    }
  }

  .middle {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
      position: relative;
    }
    span {
      cursor: pointer;
      display: inline-block;
      max-width: 300px;
      margin: 10px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #ebebf2;
    margin: 10px 0;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buy {
      width: 74px;
      height: 22px;
      background: #d4fcf2;
      border-radius: 5px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        margin-left: 4px;
      }
    }

    .el-button--text {
      color: #3b86ff;
    }
  }
}
</style>
