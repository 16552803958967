<template>
  <div class="pop-class">
    <p class="pop-class-title">热门课程</p>
    <ul>
      <CardItem v-for="item in list" :key="item.id" :info="item" />
    </ul>
  </div>
</template>

<script>
import { apiCourseList } from '@/api/course'

import CardItem from '../../components/cardItem'

export default {
  components: {
    CardItem
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const {
        data: { list }
      } = await apiCourseList({
        page: 1,
        page_size: 6,
        is_hot: 1
      })
      this.list = list
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-class {
  .pop-class-title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
    margin-top: 30px;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
}
</style>
