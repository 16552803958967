<template>
  <div class="page-wrapper">
    <!--    <div class="ad-img">-->
    <!--      <img-->
    <!--        src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1618839029639652366812.jpg"-->
    <!--        alt=""-->
    <!--      />-->
    <!--    </div>-->

    <Live />
    <New />
    <Study />
    <Msdl />
    <Promote />
    <!-- <Pop />
    <Teacher /> -->
  </div>
</template>

<script lang="js">
import Pop from "./pop"
import Teacher from "./teacher"
import Live from './liveTwo'
import New from './newIntroduction'
import Study from './study'
import Msdl from './msdl'
import Promote from './promote'



export default {
  components:{
    Live,
    Pop,
    Teacher,
    New,
    Study,
    Msdl,
    Promote
  },
}
</script>

<style lang="less" scoped>
.page-wrapper {
  .ad-img {
    > img {
      width: 100%;
      border-radius: 5px;
    }
  }
}
</style>
