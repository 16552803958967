<template>
  <div>
    <ChContent v-if="$i18n.locale == 'ch'" />
    <Empty v-else></Empty>
  </div>
</template>

<script>
import ChContent from './chHome'
import Empty from '@/components/Empty'

export default {
  components: {
    ChContent,
    Empty
  }
}
</script>

<style scoped></style>
