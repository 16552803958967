<template>
  <div style="margin-top: 40px">
    <div class="header">
      <div class="textNew">名师带练</div>
      <div class="text">高效练习不走弯路</div>
    </div>
    <ul class="list">
      <CardItem v-for="item in list" :key="item.id" :info="item" />
    </ul>
  </div>
</template>

<script>
import CardItem from '../../components/cardItem'
import { apiCourseList } from '@/api/course'

export default {
  data() {
    return {
      list: []
    }
  },
  components: {
    CardItem
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const { data } = await apiCourseList({
        cate_main: 5,
        cate_sub: 51,
        page: 1,
        page_size: 999999
      })
      this.list = data.list.splice(0, 4)
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
  .textNew {
    font-size: 26px;

    font-weight: bold;
    color: #1f1f40;
  }
  .text {
    font-size: 16px;

    font-weight: 400;
    color: #3a3a3a;
    margin-left: 12px;
    line-height: 1.5;
  }
}
.list {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
}
</style>
