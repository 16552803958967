<template>
  <div class="wrap">
    <div class="space left"></div>
    <div class="frame-wrapper">
      <Nav :onChange="handleNav" />
      <div class="content">
        <div class="banner" v-if="isTryCate && !isAllPay">
          <div class="banner-img">
            <img :src="speakBannerImg" alt="" />
          </div>
          <div class="banner-text">
            <div class="top">
              <span class="title"
                >打包购买全部{{ navData.name }}题型技巧课</span
              >
              <span class="discount">立享优惠</span>
            </div>
            <p class="tips">
              已有单独购买？仅需补差价，即可以折扣价格解锁全部题型技巧视频哦。
            </p>
            <el-button
              class="button"
              size="small"
              type="primary"
              @click="handlePay"
            >
              打包购买{{ navData.name }}
            </el-button>
          </div>
        </div>

        <Component :is="currentCom" :onList="handleList" />
      </div>

      <Pay ref="pay" />
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import Nav from './nav'

import HomePage from './home'
import NormalList from './normal/index'
import LiveList from './live/index'
import Pay from '../components/pay'
import { apiPackageBuyCheck } from '@/api/course'

import speakBannerImg from '@/assets/class/exc/speak-banner.png'

const TRY_CATE_SUB = [21, 22, 23, 24]

export default {
  components: {
    Nav,
    Pay,

    HomePage, // 首页
    NormalList, // 普通列表
    LiveList // 直播列表
  },
  data() {
    return {
      currentCom: null,

      list: [],
      navData: {}
    }
  },
  watch: {
    $route() {
      this.getCompType()
    }
  },
  computed: {
    speakBannerImg() {
      return speakBannerImg
    },
    isTryCate() {
      const { cateSub } = this.$route.query
      return TRY_CATE_SUB.includes(Number(cateSub))
    },
    isAllPay() {
      return this.list.reduce(
        (flag, m) => flag && Number(m.buy_status) === 1,
        true
      )
    }
  },
  created() {
    this.getCompType()
  },
  methods: {
    getCompType() {
      const { cateSub } = this.$route.query
      if (!cateSub) {
        this.currentCom = 'HomePage'
      } else if (Number(cateSub) === 31) {
        this.currentCom = 'LiveList'
      } else {
        this.currentCom = 'NormalList'
      }
    },
    handleList(list) {
      this.list = list
    },
    handleNav(data) {
      this.navData = data
    },
    async handlePay() {
      const { data } = await apiPackageBuyCheck({
        course_id: this.navData.id
      })
      if (data <= 0) {
        return this.$message.success('打包课程无需购买')
      }

      this.$refs.pay.open({
        name: `打包购买${this.navData.name}课`,
        courseType: '12',
        cateId: this.navData.id,
        money_total: data,
        money_ticket_total: this.navData.money
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  min-height: calc(100vh - 66px);
  .space {
    flex: 1;
    &.left {
      background-color: #f8f8f8;
    }
  }
  .frame-wrapper {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .content {
      // width: 1060px;
      flex: 1;
      margin-left: 46px;
      margin-top: 25px;
      .banner {
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        padding: 10px 17px;
        .banner-text {
          margin-left: 24px;
          .top {
            display: flex;
            align-items: center;
            .title {
              font-size: 16px;
              font-weight: bold;
            }
            .discount {
              width: 65px;
              height: 20px;
              background: #ffdfe2;
              border-radius: 50px;
              font-size: 10px;
              color: #ff606d;
              margin-left: 10px;
              text-align: center;
              line-height: 20px;
            }
          }

          .tips {
            font-size: 12px;
            color: #9c9c9c;
            margin-top: 8px;
            margin-bottom: 8px;
            font-weight: normal;
          }

          .button {
            background: #3b86ff;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
