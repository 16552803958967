<template>
  <div style="margin-top: 40px">
    <div class="header">
      <div class="textNew">新手入门</div>
      <div class="text">开启你PTE备考第一步</div>
    </div>
    <div class="boxShow">
      <div class="boxContent">
        <div class="one">
          <img src="../../../../assets/live/002.png" alt="" />
          <div class="numberText">
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
          </div>
          <div class="title">
            PTE考试是什么？<br />
            PTE考试有哪些题型？<br />
            PTE考试如何评分？<br />
            我该如何合理规划复习？
          </div>
        </div>
        <div class="two">
          <div class="title">全题型介绍</div>
          <div class="content">
            <div class="text">
              听、说、读、写<br />
              全题型一网打尽 <br />
              2.5小时Get考试要点
            </div>
            <img class="img" src="../../../../assets/live/09.png" alt="" />
            <el-button class="btn" type="primary" round @click="toCourse('1')"
              >免费听课</el-button
            >
          </div>
        </div>
        <div class="two">
          <div class="title">PTE入门必看</div>
          <div class="content">
            <div class="text">
              30天如何规划复习<br />
              PTE最大备考误区有哪些<br />
              这里帮助你不走弯路
            </div>
            <img class="img2" src="../../../../assets/live/10.png" alt="" />
            <el-button class="btn" type="primary" round @click="toCourse('2')"
              >免费听课</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    toCourse(v) {
      if (v == '2') {
        this.$router.replace({
          name: 'course',
          query: {
            ...this.$route.query,
            cateMain: 1,
            cateSub: 11
          }
        })
      } else {
        this.$router.replace({
          name: 'course',
          query: {
            ...this.$route.query,
            cateMain: 1,
            cateSub: 12
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.boxShow {
  width: 100%;
  height: 178px;
  background: #f5f6fa;
  border-radius: 10px;
  position: relative;
  .boxContent {
    display: flex;
    position: absolute;
    bottom: 20px;
    .one {
      width: 286px;
      height: 178px;
      background: #4a4a58;
      border-radius: 10px;
      display: flex;
      margin-left: 20px;
      img {
        position: absolute;
        width: 104px;
        height: 178px;
        opacity: 0.7;
      }
      .numberText {
        padding-top: 17px;
        padding-left: 33px;
        position: relative;
        div {
          width: 25px;
          height: 25px;
          background: #e3c497;
          border-radius: 50%;
          text-align: center;
          line-height: 25px;
          margin: 9px;
        }
      }
      .title {
        width: 176px;
        height: 124px;
        font-size: 16px;
        font-weight: 400;
        line-height: 34px;
        color: #e3c497;
        padding-top: 21px;
      }
    }

    .two {
      width: 286px;
      height: 178px;
      border-radius: 10px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      margin-left: 28px;
      cursor: pointer;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-8px);
      }
      .title {
        height: 42px;
        background: #4a4a58;
        border-radius: 10px 10px 0px 0px;
        line-height: 42px;
        font-size: 16px;
        color: #e3c497;
      }
      .content {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #1f1f40;
        padding-top: 14px;
        position: relative;
        .img {
          position: absolute;
          bottom: -19px;
          right: 3px;
          width: 106px;
          height: 118px;
        }
        .img2 {
          position: absolute;
          bottom: -19px;
          right: 3px;
          width: 107px;
          height: 113px;
        }
        .text {
          position: relative;
          z-index: 1;
        }
        .btn {
          width: 130px;
          height: 28px;
          background: #f7a437;
          opacity: 1;
          border-radius: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          border-color: #f7a437;
          line-height: 1px;
          margin-top: 11px;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
  .textNew {
    font-size: 26px;

    font-weight: bold;
    color: #1f1f40;
  }
  .text {
    font-size: 16px;

    font-weight: 400;
    color: #3a3a3a;
    margin-left: 12px;
    line-height: 1.5;
  }
}
</style>
