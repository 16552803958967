import { render, staticRenderFns } from "./msdl.vue?vue&type=template&id=0fe2ce63&scoped=true"
import script from "./msdl.vue?vue&type=script&lang=js"
export * from "./msdl.vue?vue&type=script&lang=js"
import style0 from "./msdl.vue?vue&type=style&index=0&id=0fe2ce63&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe2ce63",
  null
  
)

export default component.exports