<template>
  <div class="live-com" v-loading="loading">
    <div>
      <img class="bg-img" src="../../../../assets/live/zb2.png" />
      <div class="title">即将直播</div>
      <div class="content">
        <div class="img">
          <img :src="liveData.img_logo" alt="" />
          <div class="tag">直播</div>
        </div>
        <div class="info">
          <div class="name">{{ liveData.name }}</div>
          <div class="time">
            <i class="iconfont icon-time"></i>
            <div>{{ liveData.live_stime }}</div>
          </div>
          <div>
            <el-button
              class="btn"
              type="primary"
              @click="handleDetail(liveData)"
            >
              免费报名
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <img class="bg-img" src="../../../../assets/live/zb2.png" />
      <div class="title">上期回看</div>
      <div class="content">
        <div class="img">
          <img :src="videoData.img_logo" alt="" />
          <div class="tag">直播</div>
        </div>
        <div class="info">
          <div class="name">{{ videoData.name }}</div>
          <div class="label">
            <Label :label="videoData.label_id" />
          </div>
          <div class="link" @click="toDetail(videoData)">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>

    <LiveDialog ref="live" />
  </div>
</template>

<script>
import LiveDialog from '../../components/liveDialog'
import Empty from '@/components/Empty'
import Label from '../../components/label'
import { apiCourseList } from '@/api/course'

export default {
  components: {
    LiveDialog,
    Empty,
    Label
  },
  data() {
    return {
      list: [],
      currentItem: {},

      loading: false,
      liveData: null,
      videoData: null
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    handleDetail(item) {
      this.$refs.live.open(item)
      // this.$router.push({
      //   name: 'chapterDetailLive',
      //   query: {
      //     skillId: item.id
      //   }
      // })
    },
    toDetail(val) {
      // TODO: 暂时先去掉跳转
      // this.$router.push({
      //   name: 'chapterDetail',
      //   query: {
      //     skillId: val.id,
      //     type: 1,
      //   },
      // })
    },

    async fetchList() {
      this.loading = true
      const { data } = await apiCourseList({
        cate_main: 3,
        cate_sub: 31,
        page: 1,
        page_size: 999999
      })
      this.liveData = data.list.filter((m) => m.type === 1)[0]
      this.videoData = data.list.filter((m) => m.type === 2)[0]
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.live-com {
  display: flex;
  > div {
    flex: 1;
    margin-right: 28px;
    padding: 20px 26px;
    background-color: #4a4a58;
    border-radius: 10px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    .bg-img {
      position: absolute;
      left: 18px;
      top: 9px;
      width: 85px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #e3c497;
    }
    .content {
      margin-top: 10px;
      background-color: #fff;
      border-radius: 5px;
      padding: 8px;
      display: flex;
      .img {
        position: relative;
        > img {
          width: 160px;
          height: 100px;
          border-radius: 5px;
          object-fit: cover;
        }
        .tag {
          position: absolute;
          top: 6px;
          right: 6px;
          background-color: #fff;
          font-size: 12px;
          color: #f44336;
          border-radius: 8px;
          padding: 0 8px 0 12px;
          &:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 6px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #f44336;
          }
        }
      }
      .info {
        margin-left: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;
        .name {
          font-size: 14px;
          font-weight: bold;
          color: #1f1f40;
        }
        .time {
          display: flex;
          font-size: 12px;
          color: #ff606d;
          margin-top: 6px;
          flex: 1;
          > i {
            font-size: 12px;
            color: #ff606d;
            margin-right: 4px;
          }
        }
        .btn {
          width: 140px;
          height: 28px;
          border-color: #3b86ff;
          background-color: #3b86ff;
          padding: 0;
          font-size: 14px;
          border-radius: 5px;
          margin-top: 10px;
          cursor: pointer;
        }
        .label {
          margin-top: 6px;
          flex: 1;
        }
        .link {
          padding-top: 12px;
          border-top: 1px solid #ebebf2;
          text-align: right;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
