<template>
  <div class="teach-introduction">
    <p class="title">名师介绍</p>
    <ul>
      <li v-for="item in list" :key="item.id" @click="handleDetail(item)">
        <div class="img">
          <img :src="item.img_logo" alt="" />
        </div>
        <p class="name">{{ item.name }}</p>
        <p class="teacher-title">{{ item.scope_teaching }}</p>
      </li>
    </ul>
  </div>
</template>

<script lang="js">
import {apiTeacherList} from '@/api/course'

export default {
  data() {
    return {
      list: []
    }
  },
  created(){
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const {data: {list}} = await apiTeacherList({
        page: 1,
        page_size: 12
      })
      this.list = list
    },
    handleDetail(item) {
      this.$router.push({
        name: 'courseTeacherDetail',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.teach-introduction {
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
    margin-top: 30px;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 12px 10px;
      &:hover {
        .img {
          > img {
            border-color: #3b86ff;
          }
        }
        .name {
          color: #1f1f40;
        }
        .teacher-title {
          color: #1f1f40;
        }
      }
      .img {
        > img {
          cursor: pointer;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 2px solid #e8f1ff;
        }
      }
      .name {
        font-size: 14px;
        margin-top: 12px;
        color: #9c9c9c;
      }
      .teacher-title {
        margin-top: 6px;
        font-size: 12px;
        color: #9c9c9c;
      }
    }
  }
}
</style>
