<template>
  <div class="radio-class">
    <ul v-if="list && list.length" v-loading="loading">
      <Component
        :is="comName"
        v-for="item in list"
        :key="item.id"
        :info="item"
      />
    </ul>
    <Empty v-else />
  </div>
</template>

<script>
import CardItem from '../../components/cardItem'
import TryItem from '../../components/tryItem'
import Empty from '@/components/Empty'
import { apiCourseList } from '@/api/course'

const TRY_CATE_SUB = [21, 22, 23, 24]

export default {
  components: {
    CardItem,
    TryItem,
    Empty
  },
  props: {
    onList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      comName: ''
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchList()
      }
    },
    list() {
      this.onList(this.list)
    }
  },
  methods: {
    setComName() {
      const { cateSub } = this.$route.query
      if (TRY_CATE_SUB.includes(Number(cateSub))) {
        this.comName = 'TryItem'
      } else {
        this.comName = 'CardItem'
      }
    },
    async fetchList() {
      this.loading = true
      const { cateMain, cateSub } = this.$route.query
      const { data } = await apiCourseList({
        cate_main: cateMain,
        cate_sub: cateSub,
        page: 1,
        page_size: 999999
      })

      this.setComName()
      this.list = data.list
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-class {
  width: 100%;
  .radio-class-title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
  }
  .radio-class-goback-title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
    margin-top: 40px;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
}
</style>
